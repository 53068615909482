@mixin auth {
    #login-container {
        padding:20px;
        margin:0 auto;
        text-align: center;
    
        h1 {
            margin:0 0 10px;
        }
    
        form {
            margin:50px auto 0;
            width: 350px;
    
            p {
                margin:0 0 1.5em;
    
                label {
                    display: block;
                    font-weight: bold;
                    margin-bottom:.5ex;
                }
    
                input,
                button {
                    width: 100%;
                    box-sizing: border-box;
                    margin:0;
                }
    
                span {
                    display: block;
                    text-align: left;
                    margin-top:.5ex;
                }
    
                &.actions {
                    margin-top:40px;
                }
    
                &.message {
                    font-weight: bold;
                    font-size:1.2em;
                    color:#c00;
                }
            }
        }

        @content
    }
}